@import "../../typography.css";
@import "../../mixins.css";
@import "../../colors.css";

.component {
  z-index: 4;
  -webkit-transform: translate3d(0%, 0, 0);

  .skip {
    @mixin metaDescriptionText;
    @mixin background_primary;
    @mixin text_primary;
    @mixin background_primary;
    text-transform: capitalize;
    top: -1000px;
    left: -1000px;
    height: 1px;
    width: 1px;
    text-align: center;
    position: absolute;
    z-index: 9999;
    line-height: 60px;
    overflow: hidden;

    &:focus {
      top: 0;
      left: 0;
      padding: 0 $spacingSm;
      height: 60px;
      width: auto;
      height: auto;
      overflow: visible;
      box-shadow: none;
    }
  }
}