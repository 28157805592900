@import "../../typography.css";
@import "../../mixins.css";

.sitewideDiscountTitle {
  @mixin typeSecondary;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 1.5px;
}

.sitewideDiscountDescription {
  @mixin serifLightSmall;
  padding-top: $spacingXs;
  font-size: 68px;

  @media (--smartphone) {
    font-size: 64px;
  }

  @media (--tablet) {
    padding-top: $spacingXs;
    padding-bottom: 0;
  }
}

.sitewideDiscountTerms {
  @mixin promoTermsText;
  padding-top: 0;
  margin: 0 $spacingXs;
  @media (--smartphone) {
    margin: 0 $spacingMd;
  }
  @media (--tablet) {
    padding-top: $spacingXs;
  }
}

.sitewideDiscountLink {
  @mixin typePrimary;
  font-weight: 900;
  background-color: transparent;
  border: none;
}

.home {
  margin: $spacingXs $spacingXl $spacingXl $spacingXl;
  position: relative;
  overflow: hidden;
  text-align: center;
  cursor: inherit;
  height: auto;

  @media (--smartphone) {
    margin: $spacingSm;
  }

  @media (width >= 1440px) {
    margin: $spacingXs 0 $spacingXl 0;
  }
}

.plp {
  position: relative;
  overflow: hidden;
  text-align: center;
  cursor: inherit;
  width: 100%;
  height: auto;
  position: relative;
}

.copy {
  position: absolute;
  width: 100%;
  top: 50%;

  @media (--smartphone) {
    text-align: center;
    width: 100%;
  }
}

.left {
  &.copy {
    transform: translateY(-50%);

    @media (--tablet-and-desktop) {
      left: 0;
      padding-left: 10%;
      text-align: left;
    }
  }
}

.right {
  &.copy {
    transform: translateY(-50%);

    @media (--tablet-and-desktop) {
      right: 0;
      padding-right: 10%;
      text-align: right;
    }
  }
}

.center {
  &.copy {
    transform: translateY(-50%);
  }
}
